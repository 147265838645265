import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import FileList from './FlieList';
import LinearProgress from '@mui/material/LinearProgress';

import { 
  getDeal,
    getLogs,
    resetState,
    revokeFromLog,
    updateCalculatedLayout,
    updateLog
} from "./redux/actions";
import ToolBar from './ToolBar';
import DealInfoBlock from './DealInfoBlock';
import DealApprovalResultBlock from './DealApprovalResultBlock';
import ReviewTextBlock from './ReviewTextBlock';
import { Card, CardContent } from '@mui/material';
import { getPropValue } from './helpers';

function DealApprovalPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const location = useLocation();
  const navigate = useNavigate();
  const docId = location.state.docId;
  const lt = stateObj.lt;
  const currentUser = stateObj.currentUser;
  const deals = stateObj.deals;
  const $ = window.$;
  const [fileListHeight, setFileListHeight] = React.useState(400);
  const [checkData, setCheckData] = useState({checkId:0, checkLogs:[]});
  const [revoke, setRevoke] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(null);
  const [timer, setTimer] = useState(null);
  let t;
  
  useEffect(()=>{
    //для тестирования
    if (!currentUser) {
      alert("currentUser:null");
    }
    if (!deals) {
      alert("deals:null");
    }
    setLoading(true);
    dispatch(getDeal(docId, (deal)=>{
      setLoading(false);
      //console.log(deal);
      try {
        let fin = getPropValue(deal, "PROPERTY_324");
        if (fin) {
          let match = [...fin.matchAll(/(\d{1,2})\.(\d{1,2})\.(\d{4})\s(\d{1,2}):(\d{1,2}):(\d{1,2})/g)];
          if (match.length > 0) {
            match = match[0];
            let timeEnd = new Date(match[3], match[2]-1, match[1], match[4], match[5], match[6]).getTime();            
            t = setInterval(()=>{
              //setTimer(t);
              let timeStart = new Date().getTime();
              let diff = timeEnd - timeStart;
              if (diff > 0) {
                let hours   = `0${Math.floor(diff / 3.6e6)}`.substr(-2);
                let minutes = `0${Math.floor((diff % 3.6e6) / 6e4)}`.substr(-2);
                let seconds = `0${Math.floor((diff % 6e4) / 1000)}`.substr(-2);
                let _duration = hours+":"+minutes+":"+seconds;
                setDuration(_duration);
              } else {
                clearInterval(t);
                setDuration("");
              }
            },1000)
          }
        }
        let n = getPropValue(deal, "PROPERTY_300");
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        setCheckData({checkId:n, checkLogs:logs});
      } catch(e) {
        console.log(e);
      }
    }))

    return(()=>{
      clearInterval(t);
    })
  },[]);

  //console.log(duration);

  let dealInfoMinWidth = $(document).width()-600;

  const handleAgree = ()=>{
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      _.forEach(deal.LOGS_DATA, (log)=>{
        let logId = log.ID;
        dispatch(updateLog(deal.ID, logId, currentUser, "Согласовано"));
      })
    }
    navigate("/");
  }

  const handleDisagree = ()=>{
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      _.forEach(deal.LOGS_DATA, (log)=>{
        let logId = log.ID;
        dispatch(updateLog(deal.ID, logId, currentUser, "Отклонено"));
      })
    }
    navigate("/");
  }

  const handleRevoke = ()=>{
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      _.forEach(deal.LOGS_DATA, (log)=>{
        let logId = log.ID;
        dispatch(revokeFromLog(deal.ID, logId, currentUser, `Решение по согласованию группы ${log.GROUP_INFO.NAME} отозвано`));
      })
    }
    navigate("/");
  }

  if (deals && currentUser) {
    let deal = _.find(deals, x => x.ID === docId);
    let logIds = getPropValue(deal, "PROPERTY_311", -1);
    dispatch(getLogs(logIds, (logs)=>{
      _.forEach(logs, (log)=>{
        let groupInfo = log.GROUP_INFO;
        let users = getPropValue(groupInfo, "PROPERTY_276", -1);
        //console.log(users);
        let uid = _.find(users, x => x === currentUser.ID);
        if (uid === currentUser.ID) {
          let res = getPropValue(log, "PROPERTY_307");
          if (res) {
            setRevoke(true);
            return false;
          }
        }
      })
    }));
  }
  
  return (
    <ThemeProvider theme={stateObj.theme}>
      <Column left={24} right={24} bottom={80}>
        <Row top={8}><ToolBar state="approval"></ToolBar ></Row>
        { loading &&
        <LinearProgress />
        } 
        <Row top={32} bottom={24} cross={Axis.cross.center} main={Axis.main.between}>
          <Header1>Согласование №{docId}</Header1><Row left={100}><Header1>{duration ? `Ост.: ${duration}` :""}</Header1></Row>
        </Row>
        <Row>
          <Column>
            <Card>
              <CardContent>
                <DealInfoBlock docId={docId} minWidth={dealInfoMinWidth} editable={false}></DealInfoBlock>
              </CardContent>
            </Card>
            <Column top={36}>
              <Card>
                <CardContent>
                  <Row top={16} bottom={24}><Header2>Результат согласования</Header2></Row>
                  <DealApprovalResultBlock checkData={checkData}></DealApprovalResultBlock>
                </CardContent>
              </Card>
            </Column>
            { revoke === false ?
            <>
              <Row top={36}><Header2>Ваше решение</Header2></Row>
              <Row top={24} main={Axis.main.between}>
                <Row sx={{width:"calc(50% - 10px)"}}><Button fullWidth variant='contained' size='large' color='success' onClick={handleAgree}>Согласовать</Button></Row>
                <Row sx={{width:"calc(50% - 10px)"}}><Button fullWidth variant='contained' size='large' color='error' onClick={handleDisagree}>Отклонить</Button></Row>
              </Row>
            </>
            :
            <>
              <Row top={36}><Header2>Ваше решение</Header2></Row>
              <Row top={24}>
                <Row sx={{width:"calc(100%)"}}><Button fullWidth variant='contained' size='large' color='error' onClick={handleRevoke}>Отозвать согласование</Button></Row>
              </Row>
            </>
            }
          </Column>
          <Column left={16} width={500}>
            <FileList type="deal" height={fileListHeight} title="Файлы документа:" allowUploadFiles={false} allowDeleteFiles={false} docId={docId}></FileList>
            <Column top={16}>
              <Row top={0} bottom={12}><Subtitle1>Переписка по согласованию</Subtitle1></Row>
              <Card>
                <ReviewTextBlock docId={docId} allowSend={true}></ReviewTextBlock>
              </Card>
            </Column>
          </Column>
          
        </Row>
      </Column>
    </ThemeProvider>
  );
}
  
export default DealApprovalPage;
  



