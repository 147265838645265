import axios from 'axios';
import parallel from 'async/parallel';
import reflect from 'async/reflect';
import { getPropValue } from '../helpers';

import { 
  RESET_STATE,
  SET_CALCULATED_LAYOUT,
  GET_DOC_TYPES,
  GET_PARTNERS,
  GET_SIGNERS,
  GET_CRC,
  GET_PARENT_DOCS,
  GET_INITIATOR,
  GET_WORKER,
  GET_DEALS,
  GET_CURRENT_USER
} from "./types";

import _, { result } from 'lodash';
import { Co2Sharp } from '@mui/icons-material';
import uuid from 'react-uuid';

export const getDocTypes = (callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let data = { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 57, "SOCNET_GROUP_ID":71 };
  const {url, params} = prepUrl(state.rest, "lists.element.get", data);
  let result = await axios({url: url, method: 'post', data: params});
  //console.log(result.data);
  callback(result.data);
  dispatch({
    type: GET_DOC_TYPES,
    payload: {
      docTypesData: result.data
    }
  });
}

export const getCurrentUser = (testUserId, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const roles = [
    "Сотрудник",  //0
    "Аренда",     //1
    "Бухгалтерия",//2 
    "Кадры",      //3
    "Лаборатория",//4 
    "Логисты",    //5
    "Маркетинг",  //6
    "Продукция",  //7
    "Продукция (тендерный отдел)", //8
    "СБ",         //9
    "Юристы",     //10
    "Третьяков"   //11
  ];
  const devRole = 11; //Менять сотрудника тут
  let testUser = _.find(state.testUsers, x => x.LAST_NAME === roles[devRole]);
  if (testUserId) {
    testUser = _.find(state.testUsers, x => x.ID === testUserId);
  }
  //console.log(testUser);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    let data = testUser;
    let userId = data.ID;
    let d = { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 58, "SOCNET_GROUP_ID":71 };
    const {url, params} = prepUrl(state.rest, "lists.element.get", d);
    axios({url: url, method: 'post', data: params}).then((result)=>{
      let groups = [];
      _.forEach(result.data.result, (g)=>{
        let users = getPropValue(g, "PROPERTY_276", -1);
        //console.log(users);
        _.forEach(users, (u)=>{
          if (u === userId) {
            groups.push(g);
          }
        })
      })
      //callback(groups);
      data.GROUPS = groups;
      callback(data);
      dispatch({
        type: GET_CURRENT_USER,
        payload: {
          currentUser: data
        }
      });
    });
  } else {
    const $ = window.$;
    let auth = $("#auth").val();
    //console.log(auth);
    let _url;
    if (auth) {
      _url = state.rest+"user.current";
    }
    let result = await axios({url:_url, method: 'post', data: { "auth":auth }});
    //callback(result.data.result);
    let data = result.data.result;
    if (testUserId) { //для тестирования
      data = _.find(state.testUsers, x => x.ID === testUserId);
    }
    let userId = data.ID;
    let _d = { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 58, "SOCNET_GROUP_ID":71 };
    const {url, params} = prepUrl(state.rest, "lists.element.get", _d);
    axios({url: url, method: 'post', data: params}).then((result)=>{
      let groups = [];
      _.forEach(result.data.result, (g)=>{
        let users = getPropValue(g, "PROPERTY_276", -1);
        //console.log(users);
        _.forEach(users, (u)=>{
          if (u === userId) {
            groups.push(g);
          }
        })
      })
      //callback(groups);
      data.GROUPS = groups;
      callback(data);
      dispatch({
        type: GET_CURRENT_USER,
        payload: {
          currentUser: data
        }
      });
    });
  }
}

export const getGroups = (userId, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 58, "SOCNET_GROUP_ID":71 });
  axios({url: url, method: 'post', data: params}).then((result)=>{
    let groups = [];
    _.forEach(result.data.result, (g)=>{
      let users = getPropValue(g, "PROPERTY_276", -1);
      //console.log(users);
      _.forEach(users, (u)=>{
        if (u === userId) {
          groups.push(g);
        }
      })
    })
    callback(groups);
  })  
}

export const getDeals = (inProcess, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  call();

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      let d;
      if (inProcess) {
        d = { "ORDER": {"ID":"ASC"}, "filter":{">ID": id, "%PROPERTY_323":"В работе"}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "start": -1 };
      } else {
        d = { "ORDER": {"ID":"ASC"}, "filter":{">ID": id, "%PROPERTY_323":"В архиве"}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "start": -1 };
      }
      const {url, params } = prepUrl(state.rest, "lists.element.get", d);
      let result = await axios({url: url, method: 'post', data: params});
      if (result.data.result.length > 0) {
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        //console.log(items);
        callback(items);
      }
    }
  }
}



export const updateLog = (docId, logId, user, msgText) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.get",{ "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 63, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": logId} });
  let result = await axios({url: url, method: 'post', data: params});
  let data = result.data.result[0];
  let dt = new Date();
  let y = dt.getFullYear();
  let m = `0${dt.getMonth()+1}`.substr(-2);
  let d = `0${dt.getDate()}`.substr(-2);
  let currentDt = `${d}.${m}.${y}`;

  let fields = {
    PROPERTY_307: {"0": msgText},
    PROPERTY_310: {"0": currentDt},
    PROPERTY_306: {"0": user.ID}
  };
  data.PROPERTY_307 = fields.PROPERTY_307;
  data.PROPERTY_310 = fields.PROPERTY_310;
  data.PROPERTY_306 = fields.PROPERTY_306;
  //console.log(data);
  const p = prepUrl(state.rest, "lists.element.update", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 63, "SOCNET_GROUP_ID":71, "ELEMENT_ID":logId, "FIELDS": data });
  result = await axios({url: p.url, method: 'post', data: p.params});
  let newMsg = {id:uuid(), date:new Date(), text: msgText, user:user.NAME+" "+user.LAST_NAME, user_id:user.ID}; 
  dispatch(addReviewMessage(docId, newMsg, (data)=>{}));
  console.log(result.data);
}


export const revokeFromLog = (docId, logId, user, msgText) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 63, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": logId} });
  let result = await axios({url: url, method: 'post', data: params});
  let data = result.data.result[0];

  delete data.PROPERTY_307;
  delete data.PROPERTY_310;
  delete data.PROPERTY_306;
  const p = prepUrl(state.rest, "lists.element.update", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 63, "SOCNET_GROUP_ID":71, "ELEMENT_ID":logId, "FIELDS": data });
  result = await axios({url: p.url, method: 'post', data: p.params});
  let newMsg = {id:uuid(), date:new Date(), text: msgText, user:user.NAME+" "+user.LAST_NAME, user_id:user.ID}; 
  dispatch(addReviewMessage(docId, newMsg, (data)=>{}));
  console.log(result.data);
}

export const getDeal = (id, callback) => async (dispatch, getState) => {
  if (!id) {
    return;
  }
  const state = getState().mainState;
  const {url, params} = prepUrl(state.rest, "lists.element.get", { "ORDER": {"ID":"ASC"}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": id} });
  let result = await axios({url: url, method: 'post', data: params});
  //console.log(result.data);
  callback(result.data.result[0]);
}

export const getPartners = (searchTerm) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const stamp = Date.now();
  if (window.prevHttpCall === undefined) {
    window.prevHttpCall = 0;
  }
  let diff = stamp-window.prevHttpCall;
  if ((diff >= 500) ) {
    call();
  } 
  window.prevHttpCall = stamp;

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      //"select": ["ID", "TITLE"]
      const {url, params} = prepUrl(state.rest, "crm.company.list", { "order": {'ID': 'ASC'}, "filter":{"%TITLE":searchTerm, ">ID": id}, 'SELECT': ['*', 'UF_*'], "start": -1 });
      let result = await axios({url: url, method: 'post', data: params});
      if (result.data.result.length > 0) {
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        dispatch({
          type: GET_PARTNERS,
          payload: {
            partners: items
          }
        });
        
      }
    }
    
  }
}

export const getPartner = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "crm.company.list", { "filter":{"=ID": id}, 'SELECT': ['*', 'UF_*'] });
  let result = await axios({url: url, method: 'post', data: params});
  callback(result.data.result[0]);
}

export const getParentDocs = (searchTerm) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const stamp = Date.now();
  if (window.prevHttpCall === undefined) {
    window.prevHttpCall = 0;
  }
  let diff = stamp-window.prevHttpCall;
  if ((diff >= 1000) ) {
    call();
  } 
  window.prevHttpCall = stamp;

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      const {url, params} = prepUrl(state.rest, "lists.element.get", { "order": {'ID': 'DESC'}, "filter":{"%NAME":searchTerm, ">ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "start": -1 });
      let result = await axios({url: url, method: 'post', data: params});
      if (result.data.result.length > 0) {
        //console.log(result.data.result.length);
        items = items.concat(result.data.result);
        //console.log(items);
        items = _.filter(items, x => getPropValue(x, "PROPERTY_314") !== 'Отозван' && getPropValue(x, "PROPERTY_314") !== 'Отклонен');
        //console.log(items);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        items = _.orderBy(items, (x)=>{ return x.ID}, ["desc"]);
        dispatch({
          type: GET_PARENT_DOCS,
          payload: {
            parents: items
          }
        });
      }
    }
  }
}

export const getParentDoc = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": id} });
  let result = await axios({url: url, method: 'post', data: params});
  //console.log(result.data);
  callback(result.data.result[0]);
}

export const removeDeal = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.delete", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "ELEMENT_ID": id });
  let result = await axios({url: url, method: 'post', data: params});
  //console.log(result.data);
  callback(result.data.result);
}

export const getInitiators = (searchTerm) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const stamp = Date.now();
  if (window.prevHttpCall === undefined) {
    window.prevHttpCall = 0;
  }
  let diff = stamp-window.prevHttpCall;
  if ((diff >= 500) ) {
    call();
  } 
  window.prevHttpCall = stamp;

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      const {url, params} = prepUrl(state.rest, "user.get", { "order": {'ID': 'ASC'}, "filter":{"%LAST_NAME":searchTerm, ">ID": id}, "start": -1 });
      let result = await axios({url: url, method: 'post', data: params});
      if (result.data.result.length > 0) {
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        //console.log(items);
        dispatch({
          type: GET_INITIATOR,
          payload: {
            initiators: items
          }
        });
      }
    }
  }
}

export const getInitiator = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "user.get", { "filter":{"=ID": id} });
  let result = await axios({url: url, method: 'post', data: params});
  callback(result.data.result[0]);
}

export const sendReviewMessage = (docId, message, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const {url, params} = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  let result = await axios({url: url, method: 'post', data: params});
  let orig = {...result.data.result[0]};
  orig.PREVIEW_TEXT = message;
  let data = {
    "IBLOCK_TYPE_ID":"lists_socnet", 
    "IBLOCK_ID": 60, 
    "SOCNET_GROUP_ID":71, 
    "ELEMENT_ID":docId,
    "FIELDS":orig
  }
  let p = prepUrl(state.rest, "lists.element.update", data);
  result = await axios({url: p.url, method: 'post', data: p.params});
  p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0].PREVIEW_TEXT);
}

export const addReviewMessage = (docId, message, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  let result = await axios({url: p.url, method: 'post', data: p.params});
  let orig = {...result.data.result[0]};
  if (!orig.PREVIEW_TEXT) {
    orig.PREVIEW_TEXT = "[]";
  }
  let messages = JSON.parse(orig.PREVIEW_TEXT);
  messages.push(message);
  orig.PREVIEW_TEXT = JSON.stringify(messages);
  let data = {
    "IBLOCK_TYPE_ID":"lists_socnet", 
    "IBLOCK_ID": 60, 
    "SOCNET_GROUP_ID":71, 
    "ELEMENT_ID":docId,
    "FIELDS":orig
  }
  //console.log(orig);
  p = prepUrl(state.rest, "lists.element.update", data);
  result = await axios({url: p.url, method: 'post', data: p.params});
  p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0].PREVIEW_TEXT);
}

export const getReviewMessage = (docId, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  let result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0].PREVIEW_TEXT);
}

export const getWorkers = (searchTerm) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const stamp = Date.now();
  if (window.prevHttpCall === undefined) {
    window.prevHttpCall = 0;
  }
  let diff = stamp-window.prevHttpCall;
  if ((diff >= 500) ) {
    call();
  } 
  window.prevHttpCall = stamp;

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      let p = prepUrl(state.rest, "user.get", { "order": {'ID': 'ASC'}, "filter":{"%LAST_NAME":searchTerm, ">ID": id}, "start": -1 });
      let result = await axios({url: p.url, method: 'post', data: p.params});
      if (result.data.result.length > 0) {
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        //console.log(items);
        dispatch({
          type: GET_WORKER,
          payload: {
            workers: items
          }
        });
      }
    }
  }
}

export const getWorker = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  const p = prepUrl(state.rest, "user.get",{ "filter":{"=ID": id} });
  let result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0]);
}

export const setDeal = (docId, fields, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let result;
  if (docId) {
    let p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
    result = await axios({url: p.url, method: 'post', data: p.params});
    let orig = {...result.data.result[0]};
    _.forEach(Object.keys(fields), (key)=>{
      /*let field = orig[key];
      if (field) {
        orig[key] = fields[key];
      }*/
      orig[key] = fields[key];
    });
    let data = {
      "IBLOCK_TYPE_ID":"lists_socnet", 
      "IBLOCK_ID": 60, 
      "SOCNET_GROUP_ID":71, 
      "ELEMENT_ID":docId,
      "FIELDS":orig
    }
    p = prepUrl(state.rest, "lists.element.update", data);
    result = await axios({url: p.url, method: 'post', data: p.params});
  } else {
    let data = {
      "IBLOCK_TYPE_ID":"lists_socnet", 
      "IBLOCK_ID": 60, 
      "SOCNET_GROUP_ID":71, 
      "ELEMENT_CODE":uuid(),
      "FIELDS":fields
    }
    let p = prepUrl(state.rest, "lists.element.add", data);
    result = await axios({url: p.url, method: 'post', data: p.params});
  }
  let id = result.data.result;
  if (id) {
    if (docId) {
      callback(docId);
    } else {
      callback(id);
    }
  }
}

export const setCheck = (docId, fields, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let result;
  if (docId) {
    let p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 61, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
    result = await axios({url: p.url, method: 'post', data: p.params});
    let orig = {...result.data.result[0]};
    console.log(orig);
    try {
      delete orig.PROPERTY_283[Object.keys(orig.PROPERTY_283)[0]].TYPE
    } catch(e) { }
    _.forEach(Object.keys(fields), (key)=>{
      orig[key] = fields[key];
    });
    let data = {
      "IBLOCK_TYPE_ID":"lists_socnet", 
      "IBLOCK_ID": 61, 
      "SOCNET_GROUP_ID":71, 
      "ELEMENT_ID":docId,
      "FIELDS":orig
    }
    console.log(data);
    p = prepUrl(state.rest, "lists.element.update", data);
    result = await axios({url: p.url, method: 'post', data: p.params});
  }
  let id = result.data.result;
  if (id) {
    if (docId) {
      callback(docId);
    } else {
      callback(id);
    }
  }
}

export const getCheck = (docId, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let p = prepUrl(state.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 61, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": docId} });
  let result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0]);
}

export const getSigners = (callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  call();

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      let p = prepUrl(state.rest, "lists.element.get", { "order": {'ID': 'ASC'}, "filter":{">ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 59, "SOCNET_GROUP_ID":71, "start": -1 });
      let result = await axios({url: p.url, method: 'post', data: p.params});
      if (result.data.result.length > 0) {
        //console.log(result.data.result.length);
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        callback(items);
        finish = true;
        dispatch({
          type: GET_SIGNERS,
          payload: {
            signers: items
          }
        });
      }
    }
  }
}

export const selectSigners = (ids, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let funcs = [];
  _.forEach(ids, (id)=>{
    funcs.push((callback)=>{
      let p = prepUrl(state.rest, "lists.element.get", { "filter":{"=ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 59, "SOCNET_GROUP_ID":71 });
      axios({url: p.url, method: 'post', data: p.params}).then((result)=>{
        callback(null, result.data.result[0]);
      })
    });
    
    parallel(funcs, (err, results)=>{
      callback(results);
    })
  })
}

export const getSigner = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  let p = prepUrl(state.rest, "lists.element.get", { "filter":{"=ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 59, "SOCNET_GROUP_ID":71 });
  let result = await axios({url: p.url, method: 'post', data: p.params});
  callback(result.data.result[0]);
}

export const getLogs = (ids, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  if (ids && ids.length === 0) {
    return;
  }
  let funcs = [];
  _.forEach(ids, (id)=>{
    funcs.push((callback)=>{
      let p = prepUrl(state.rest, "lists.element.get", { "filter":{"=ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 63, "SOCNET_GROUP_ID":71 });
      axios({url: p.url, method: 'post', data: p.params}).then((result)=>{
        callback(null, result.data.result[0]);        
      })
    })
  });

  parallel(funcs, (err, results)=>{
    //console.log(results);
    let _funcs = [];
    _.forEach(results, (r)=>{
      let group = getPropValue(r, "PROPERTY_305");
      _funcs.push((callback)=>{
        let p = prepUrl(state.rest, "lists.element.get", { "filter":{"=ID": group}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 58, "SOCNET_GROUP_ID":71 });
        axios({url: p.url, method: 'post', data: p.params}).then((result)=>{
          callback(null, result.data.result[0]);        
        })          
      })
    });
    parallel(_funcs, (err, group_results)=>{
      _.forEach(results, (r)=>{
        let group = getPropValue(r, "PROPERTY_305");
        let g = _.find(group_results, g => g.ID === group);
        //console.log(g);
        r.GROUP_INFO = g;
      })
      //console.log(group_results);
      callback(results);
    })
    
  })
  //callback(result.data.result[0]);
}



export const getCrc = (callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();
  call();

  async function call() {
    let count = 0;
    let finish = false;
    let id = 0;
    let items = [];
    while(!finish && count < 100) {
      count++;
      let p = prepUrl(state.rest, "lists.element.get", { "order": {'ID': 'ASC'}, "filter":{">ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 62, "SOCNET_GROUP_ID":71, "start": -1 });
      let result = await axios({url: p.url, method: 'post', data: p.params});
      if (result.data.result.length > 0) {
        //console.log(result.data.result.length);
        items = items.concat(result.data.result);
        id = items[items.length-1].ID;
      } else {
        finish = true;
        callback(items);
        dispatch({
          type: GET_CRC,
          payload: {
            crc: items
          }
        });
      }
    }
    //console.log(items);
  }
}

export const getDataForDeal = (id, callback) => async (dispatch, getState) => {
  const state = getState().mainState;
  const $ = window.$;
  let auth = $("#auth").val();

  function _getDocTypes(callback) {
    let data = { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 57, "SOCNET_GROUP_ID":71 };
    const {url, params} = prepUrl(state.rest, "lists.element.get", data);
    axios({url: url, method: 'post', data: params}).then((result)=>{
      let items = [...result.data.result];
      items.sort(function (a, b) {
        return a.NAME.localeCompare(b.NAME);
      });
      result.data.result = items;
      callback(null, {"doc_types": result.data});
    })
  }

  function _getSigners(callback) {
    call();

    async function call() {
      let count = 0;
      let finish = false;
      let id = 0;
      let items = [];
      while(!finish && count < 100) {
        count++;
        //console.log(count);
        let data = { "order": {'ID': 'ASC'}, "filter":{">ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 59, "SOCNET_GROUP_ID":71, "start": -1 };
        const {url, params} = prepUrl(state.rest, "lists.element.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        if (result.data.result.length > 0) {
          items = items.concat(result.data.result);
          id = items[items.length-1].ID;
        } else {
          finish = true;
          callback(null, {"signers": items});
        }
      }
    }
  }

  function _getCrc(callback) {
    call();
  
    async function call() {
      let count = 0;
      let finish = false;
      let id = 0;
      let items = [];
      while(!finish && count < 100) {
        count++;
        let data = { "order": {'ID': 'ASC'}, "filter":{">ID": id}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 62, "SOCNET_GROUP_ID":71, "start": -1 };
        const {url, params} = prepUrl(state.rest, "lists.element.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        if (result.data.result.length > 0) {
          items = items.concat(result.data.result);
          id = items[items.length-1].ID;
        } else {
          finish = true;
          callback(null, {"crc":items});
        }
      }
      //console.log(items);
    }
  }

  function _getDeal(callback) {
    call();

    async function call() {
      if (id) {
        let data = { "ORDER": {"ID":"ASC"}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": id} };
        const {url, params} = prepUrl(state.rest, "lists.element.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        callback(null, {"deal": result.data.result[0]});
      } else {
        callback(null, {"deal": null});
      }
    }
  }
  
  parallel([_getDocTypes, _getSigners, _getCrc, _getDeal], (err, results)=>{
    let dealData;
    
    if (!id) {
      callback(results);
      return;
    }
    _.forEach(results, (item)=>{
      if (item.deal) {
        dealData = item.deal;
        return false;
      }
    })

    let partnerId = getPropValue(dealData, "PROPERTY_288");
    let signerId = getPropValue(dealData, "PROPERTY_290");
    let initiatorId = getPropValue(dealData, "PROPERTY_297");
    let workerId = getPropValue(dealData, "PROPERTY_312");

    let parentDocId;
    if (dealData.hasOwnProperty("PROPERTY_295")) {
      parentDocId = getPropValue(dealData, "PROPERTY_295");
    }

    function _getPartner(callback) {
      call();
  
      async function call() {
        let data = { "filter":{"=ID": partnerId}, 'SELECT': ['*', 'UF_*'] };
        const {url, params} = prepUrl(state.rest, "crm.company.list", data);
        let result = await axios({url: url, method: 'post', data: params});
        callback(null, {"partner":result.data.result[0]});
      }
    }

    function _getSigner(callback) {
      call();
  
      async function call() {
        let data = { "filter":{"=ID": signerId}, "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 59, "SOCNET_GROUP_ID":71 };
        const {url, params} = prepUrl(state.rest, "lists.element.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        callback(null, {"signer":result.data.result[0]});
      }
    }

    function _getInitiator(callback) {
      call();

      async function call() {
        let data = { "filter":{"=ID": initiatorId} };
        const {url, params} = prepUrl(state.rest, "user.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        callback(null, {"initiator":result.data.result[0]});
      }
    }

    function _getWorker(callback) {
      call();

      async function call() {
        let data = { "filter":{"=ID": workerId} };
        const {url, params} = prepUrl(state.rest, "user.get", data);
        let result = await axios({url: url, method: 'post', data: params});
        callback(null, {"worker":result.data.result[0]});
      }
    }

    function _getParentDoc(callback) {
      call();

      async function call() {
        if (parentDocId) {
          let data = { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": 60, "SOCNET_GROUP_ID":71, "FILTER": {"=ID": parentDocId} };
          const {url, params} = prepUrl(state.rest, "lists.element.get", data);
          let result = await axios({url: url, method: 'post', data: params});
          callback(null, {"parent_doc": result.data.result[0]});
        } else {
          callback(null, {"parent_doc": null});
        }
      }
    }

    parallel([_getPartner, _getSigner, _getWorker, _getInitiator, _getParentDoc], (err, r)=>{
      results = results.concat(r);
      callback(results);
    })
        
  })

}

export const resetState = (payload) => (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_STATE,
      payload: payload,
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const updateCalculatedLayout = (lt) => (dispatch, getState) => {
  try {
    dispatch({
      type: SET_CALCULATED_LAYOUT,
      payload: {
        lt: lt,
        errMessage: ""
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export function prepUrl(baseUrl, endPoint, data){
  let url;
  let d = {...data};
  if (!d) {
    d = {};
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    url = `${baseUrl}86/${process.env.REACT_APP_REST_API_KEY}/${endPoint}`;
  } else {
    const $ = window.$;
    let auth = $("#auth").val();
    url = `${baseUrl}${endPoint}`;
    d.auth = auth;
  }
  return { url:url, params:d };
}
