import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import FileList from './FlieList';
import LinearProgress from '@mui/material/LinearProgress';
import uuid from 'react-uuid';

import { 
  getDeal,
  resetState,
  updateCalculatedLayout,
  setDeal,
  addReviewMessage
} from "./redux/actions";
import ToolBar from './ToolBar';
import DealInfoBlock from './DealInfoBlock';
import DealApprovalResultBlock from './DealApprovalResultBlock';
import ReviewTextBlock from './ReviewTextBlock';
import { Card, CardContent } from '@mui/material';
import { getPropValue } from './helpers';

function DealSigningPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const dealBlockInfoData = stateObj.dealBlockInfoData;
  const location = useLocation();
  const navigate = useNavigate();
  const docId = location.state.docId;
  const lt = stateObj.lt;
  const deals = stateObj.deals;
  const currentUser = stateObj.currentUser;
  const $ = window.$;
  const [fileListHeight, setFileListHeight] = React.useState(400);
  const [checkData, setCheckData] = useState({checkId:0, checkLogs:[]});
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState([]);
  
  useEffect(()=>{
    setLoading(true);
    dispatch(getDeal(docId, (deal)=>{
      setLoading(false);
      try {
        let n = getPropValue(deal, "PROPERTY_300");
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        setCheckData({checkId:n, checkLogs:logs});
      } catch(e) {}
    }))
  },[]);

  console.log(dealBlockInfoData);

  let dealInfoMinWidth = $(document).width()-600;

  function validate() {
    let e = [];
    setErrMessage(e);
    if (!dealBlockInfoData.numDoc) {
      e.push("Введите номер документа");
    }
    if (dealBlockInfoData.amountDoc === '0' || dealBlockInfoData.amountDoc === '' || dealBlockInfoData.amountDoc === 0 || dealBlockInfoData.amountDoc === null) {
      e.push("Введите сумму");
    }
    if (!dealBlockInfoData.crcValue) {
      e.push("Выберите валюту");
    }
    if (e.length === 0) {
      return null;
    } else {
      return e;
    }
  }

  const handleSigning = () => {
    let e = validate();
    if (e) {
      setErrMessage(e);
      return;
    }
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = `0${dt.getMonth()+1}`.substr(-2);
      let d = `0${dt.getDate()}`.substr(-2);
      let currentDt = `${d}.${m}.${y}`;
      let fields = {
        "PROPERTY_314": {
          "0": "Подписан"
        },
        "PROPERTY_316": {
          "0": currentDt
        },
        "PROPERTY_291": {
          "0": `${dealBlockInfoData.dateValue.$D}.${dealBlockInfoData.dateValue.$M+1}.${dealBlockInfoData.dateValue.$y}`
        },
        "PROPERTY_292": {
          "0": dealBlockInfoData.numDoc
        },
        "PROPERTY_293": {
          "0": dealBlockInfoData.amountDoc
        },
        "PROPERTY_294": {
          "0": dealBlockInfoData.crcValue
        },
      }

      let newMsg = {id:uuid(), date:new Date(), text: "Документ подписан", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
        dispatch(addReviewMessage(deal.ID, newMsg, (data)=>{
          //console.log(data);
          dispatch(setDeal(deal.ID, fields, (id)=>{
            navigate('/archive', { state: {} });
          }));
        }));
      
    }
  }
  
  const handleRevoke = () => {
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = `0${dt.getMonth()+1}`.substr(-2);
      let d = `0${dt.getDate()}`.substr(-2);
      let currentDt = `${d}.${m}.${y}`;
      let fields = {
        "PROPERTY_314": {
          "0": "Отозван"
        },
        "PROPERTY_316": {
          "0": currentDt
        }
      }

      let newMsg = {id:uuid(), date:new Date(), text: "Документ отозван с подписания", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
        dispatch(addReviewMessage(deal.ID, newMsg, (data)=>{
          //console.log(data);
          dispatch(setDeal(deal.ID, fields, (id)=>{
            navigate('/archive', { state: {} });
          }));
        }));
      
    }
  }

  return (
    <ThemeProvider theme={stateObj.theme}>
      <Column left={24} right={24} bottom={80}>
        <Row top={8}><ToolBar state="sign"></ToolBar ></Row>
        { loading &&
        <LinearProgress />
        }
        <Row top={32} bottom={24}>
          <Header1>Подписание документа №{docId}</Header1>
        </Row>
        <Row>
          <Column>
            <Card>
              <CardContent>
                <DealInfoBlock docId={docId} minWidth={dealInfoMinWidth} editable={true}></DealInfoBlock>
              </CardContent>
            </Card>
            <Column top={36}>
              <Card>
                <CardContent>
                  <Row top={16} bottom={24}><Header2>Результат согласования</Header2></Row>
                  <DealApprovalResultBlock checkData={checkData}></DealApprovalResultBlock>
                </CardContent>
              </Card>
            </Column>
            { errMessage && errMessage.length > 0 &&
              <Column top={24}>
                {errMessage.map((e)=>{
                  return(<Row top={0} key={e}><Body1 color='red'>{e}</Body1></Row>)
                })}
              </Column>
            }
            
            <Row top={36}><Header2>Результат подписания</Header2></Row>
            <Row top={24} main={Axis.main.between}>
              <Row sx={{width:'calc(50% - 10px)'}}><Button fullWidth variant='contained' size='large' color='success' onClick={handleSigning}>Подписан</Button></Row>
              <Row sx={{width:'calc(50% - 10px)'}}><Button fullWidth variant='contained' size='large' color='error' onClick={handleRevoke}>Отозвать</Button></Row>
            </Row>
          </Column>
          <Column left={16} width={500}>
            <FileList type="deal" height={fileListHeight} title="Файлы документа:" allowUploadFiles={false} allowDeleteFiles={false} docId={docId}></FileList>
            <Column top={16} bottom={16}>
              <Row top={0} bottom={12}><Subtitle1>Переписка по согласованию</Subtitle1></Row>
              <Card>
                <ReviewTextBlock docId={docId} allowSend={false}></ReviewTextBlock>
              </Card>
            </Column>
            <FileList type="deal-scan" height={fileListHeight} title="Сканы подписанного документа:" allowUploadFiles={true} allowDeleteFiles={true} docId={docId}></FileList>
          </Column>
        </Row>
      </Column>
    </ThemeProvider>
  );
}
  
export default DealSigningPage;
  



