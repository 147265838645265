import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import FileList from './FlieList';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

import { 
    resetState,
    updateCalculatedLayout,
    getDocTypes,
    getPartners,
    getSigners,
    getCrc,
    getParentDocs,
    getInitiators,
    getWorkers,
    setDeal,
    getDeal,
    getPartner,
    getSigner,
    getInitiator,
    getWorker,
    getParentDoc,
    removeDeal,
    getDataForDeal
} from "./redux/actions";
import ToolBar from './ToolBar';
import axios from 'axios';
import { getPropValue } from './helpers';

function NewDealPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const initDocId = location.state.initDocId;
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const docTypesData = stateObj.docTypesData;
  const partners = stateObj.partners;
  const signers = stateObj.signers;
  const parents = stateObj.parents;
  const initiators = stateObj.initiators;
  const workers = stateObj.workers;
  const crc = stateObj.crc;
  const $ = window.$;
  const [docType, setDocType] = React.useState('');
  const [whoLoad, setWhoLoad] = React.useState('');
  const [partnerValue, setPartnerValue] = React.useState('');
  const [signingTypeValue, setSigningTypeValue] = React.useState('');
  const [signerValue, setSignerValue] = React.useState('');
  const [dateValue, setDate] = React.useState(dayjs(new Date()));
  const [numDoc, setNumDoc] = React.useState('');
  const [amountDoc, setAmountDoc] = React.useState('');
  const [crcValue, setCrcValue] = React.useState('');
  const [parentDocValue, setParentDoc] = React.useState('');
  const [nameDocValue, setNameDoc] = React.useState('');
  const [techPersonValue, setTechPerson] = React.useState('');
  //const [workerValue, setWorker] = React.useState('');
  const [commentValue, setComment] = React.useState('');
  const [fileListHeight, setFileListHeight] = React.useState(400);
  const [inputPartner, setInputPartner] = React.useState('');
  const [inputParent, setInputParent] = React.useState('');

  const [initiatorValue, setInitiator] = React.useState('');
  const [inputInitiator, setInputInitiator] = React.useState('');

  const [workerValue, setWorker] = React.useState('');
  const [inputWorker, setInputWorker] = React.useState('');

  const [docId, setDocId] = useState(initDocId);

  const [errMessage, setErrMessage] = useState([]);
  const [loading, setLoading] = useState(false);

  const signingType = [
    {id:1, name:"По ЭДО"},
    {id:2, name:"На бумаге"},
    {id:3, name:"По сканам"},
  ];

  const who = [
    {id:1, name:"Мы (продажа)"},
    {id:2, name:"Контрагент (покупка)"}
  ];
  
  useEffect(()=>{    
    setTimeout(()=>{
      const commentHeight = $('#comment-doc').parent().outerHeight();
      setFileListHeight(400+commentHeight-(56+56+24+24+7));
    },100)

    //initDocId
    setLoading(true);
    dispatch(getDataForDeal(initDocId, (results)=>{
      setLoading(false);
      //console.log(results);
      let doc_types = _.find(results, (x)=>{return x.doc_types !== undefined}).doc_types;
      let signers = _.find(results, (x)=>{return x.signers !== undefined}).signers;
      let crc = _.find(results, (x)=>{return x.crc !== undefined}).crc;
      let deal = _.find(results, (x)=>{return x.deal !== undefined}).deal;
      dispatch(resetState({docTypesData:doc_types, signers:signers, crc:crc}));
      if (initDocId) {
        let partner = _.find(results, (x)=>{return x.partner !== undefined}) ? _.find(results, (x)=>{return x.partner !== undefined}).partner : null;
        let signer = _.find(results, (x)=>{return x.signer !== undefined}) ? _.find(results, (x)=>{return x.signer !== undefined}).signer : null;
        let worker = _.find(results, (x)=>{return x.worker !== undefined}) ? _.find(results, (x)=>{return x.worker !== undefined}).worker : null;
        let initiator = _.find(results, (x)=>{return x.initiator !== undefined}) ? _.find(results, (x)=>{return x.initiator !== undefined}).initiator : null;
        let parent_doc = _.find(results, (x)=>{return x.parent_doc !== undefined}) ? _.find(results, (x)=>{return x.parent_doc !== undefined}).parent_doc : null;

        setNameDoc(getPropValue(deal, "PROPERTY_296"));
        setNumDoc(getPropValue(deal, "PROPERTY_292"));
        setAmountDoc(getPropValue(deal, "PROPERTY_293"));
        setCrcValue(getPropValue(deal, "PROPERTY_294") ? getPropValue(deal, "PROPERTY_294"):"");
        setDocType(getPropValue(deal, "PROPERTY_286"));
        let w = _.find(who, (x)=>{return x.name === getPropValue(deal, "PROPERTY_287")}) ? _.find(who, (x)=>{return x.name === getPropValue(deal, "PROPERTY_287")}).id : null;
        if (w) {
          setWhoLoad(w);
        }
        let st = _.find(signingType, (x)=>{return x.name === getPropValue(deal, "PROPERTY_289")}) ? _.find(signingType, (x)=>{return x.name === getPropValue(deal, "PROPERTY_289")}).id : null;
        if (st) {
          setSigningTypeValue(st);
        }
        if (deal.DETAIL_TEXT) {
          let detText = !deal.DETAIL_TEXT ? "" : deal.DETAIL_TEXT;
          setComment(detText);
        }
        if (partner) {
          setPartnerValue(partner);
        }
        setSignerValue(getPropValue(deal, "PROPERTY_290"));
        if (initiator) {
          setInitiator(initiator);
        }
        if (worker) {
          setWorker(worker);
        }
        if (parent_doc) {
          setParentDoc(parent_doc);
        }
      } else {
        if (stateObj.currentUser) {
          setInitiator(stateObj.currentUser);
        }
      }
    }))
  },[]);

  const handleChangeDocType = (event) => {
    setDocType(event.target.value);
  };

  const handleChangeWhoLoad = (event) => {
    setWhoLoad(event.target.value);
  };

  const handleChangePartner = (event, newValue) => {
    if (event.target.value === null || event.target.value === undefined || newValue === null || newValue === undefined) {
      setPartnerValue("");
      setInputPartner("");
      dispatch(resetState({partners:null}));
      return;
    }
    setPartnerValue(newValue);
    console.log(newValue);
  };

  const handleSigningType = (event) => {
    setSigningTypeValue(event.target.value);
  };

  const handleSigner = (event) => {
    setSignerValue(event.target.value);
  };

  const handleChangeDate = (newValue) => {
    let d = dayjs(new Date(newValue.$d));
    setDate(d);
    //console.log(d);
  }

  const handleNumDoc = (e) => {
    setNumDoc(e.target.value);
  }

  const handleAmountDoc = (e) => {
    setAmountDoc(e.target.value);
  }

  const handleCrc = (event) => {
    setCrcValue(event.target.value);
  }

  const handleParentDoc = (event, newValue) => {
    //setParentDoc(event.target.value);
    if (event.target.value === null || event.target.value === undefined || newValue === null || newValue === undefined) {
      setParentDoc("");
      setInputParent("");
      dispatch(resetState({parents:null}));
      return;
    }
    setParentDoc(newValue);
    console.log(newValue);
  }

  const handleNameDoc = (event) => {
    setNameDoc(event.target.value);
  }

  const handleComment = (event) => {
    setComment(event.target.value);
  }

  const handleInputParnerChange = (event, newInputValue)=>{
    
    if (!newInputValue) {
      setPartnerValue("");
      setInputPartner("");
      dispatch(resetState({partners:null}));
      return;
    }

    setInputPartner(newInputValue);
    if (newInputValue && newInputValue.length >= 3) {
      //console.log(newInputValue);
      dispatch(getPartners(newInputValue));
    } else {
      dispatch(resetState({partners:null}));
    }
  }

  const handleInputParentDocChange = (event, newInputValue) => {
    if (!newInputValue) {
      setParentDoc("");
      setInputParent("");
      dispatch(resetState({parents:null}));
      return;
    }
    setInputParent(newInputValue);
    if (newInputValue && newInputValue.length >= 3) {
      dispatch(getParentDocs(newInputValue));
    } else {
      dispatch(resetState({parents:null}));
    }
  }
  //<Files title="Файлы документа" allowRemoveFiles={true} allowUploadFiles={true}></Files>
  
  const handleInitiator = (event, newValue) => {
    if (event.target.value === null || event.target.value === undefined || newValue === null || newValue === undefined) {
      setInitiator("");
      setInputInitiator("");
      dispatch(resetState({initiators:null}));
      return;
    }
    setInitiator(newValue);
    console.log(newValue);
  }

  const handleInputInitiatorChange = (event, newInputValue) => {
    if (!newInputValue) {
      setInitiator("");
      setInputInitiator("");
      dispatch(resetState({initiators:null}));
      return;
    }
    setInputInitiator(newInputValue);
    if (newInputValue && newInputValue.length >= 3) {
      dispatch(getInitiators(newInputValue));
    } else {
      dispatch(resetState({initiators:null}));
    }
  }

  //---
  const handleWorker = (event, newValue) => {
    if (event.target.value === null || event.target.value === undefined || newValue === null || newValue === undefined) {
      setWorker("");
      setInputWorker("");
      dispatch(resetState({workers:null}));
      return;
    }
    setWorker(newValue);
    console.log(newValue);
  }

  const handleInputWorkerChange = (event, newInputValue) => {
    if (!newInputValue) {
      setWorker("");
      setInputWorker("");
      dispatch(resetState({workers:null}));
      return;
    }
    setInputWorker(newInputValue);
    if (newInputValue && newInputValue.length >= 3) {
      dispatch(getWorkers(newInputValue));
    } else {
      dispatch(resetState({workers:null}));
    }
  }

  const handleSave = () => {
    let e = validate();
    if (e) {
      setErrMessage(e);
      return;
    }
    
    saveDoc((id)=>{
      setDocId(id);
    })
  }

  function saveDoc(callback) {
    let dt = _.find(docTypesData.result, (x)=>{ return x.ID === `${docType}`});
    let yyyy = dateValue.$y;
    let m = `0${dateValue.$M+1}`.substr(-2);
    let d = `0${dateValue.$D}`.substr(-2);
    let nameDoc = `${partnerValue.TITLE} – ${dt.NAME} №${numDoc} от ${d}.${m}.${yyyy}`;
    
    let status = "Черновик";

    let fields = {
      "NAME": nameDoc,
      "PROPERTY_286": {
        "0":docType 
      },
      "PROPERTY_287": {
        "0": _.find(who, (x)=>{ return x.id === whoLoad}).name
      },
      "PROPERTY_288": {
        "0": partnerValue.ID
      },
      "PROPERTY_289": {
        "0": _.find(signingType, (x)=>{ return x.id === signingTypeValue}).name
      },
      "PROPERTY_290": {
        "0": signerValue
      },
      "PROPERTY_291": {
        "0": `${d}.${m}.${yyyy}`
      },
      /*"PROPERTY_292": {
        "0": numDoc
      },
      "PROPERTY_293": {
        "0": amountDoc
      },
      "PROPERTY_294": {
        "0": crcValue
      },*/
      "PROPERTY_295": {
        "0": parentDocValue
      },
      "PROPERTY_296": {
        "0": nameDocValue
      },
      "PROPERTY_297": {
        "0": initiatorValue.ID
      },
      "PROPERTY_312": {
        "0": workerValue.ID
      },
      "DETAIL_TEXT": commentValue,
      "PROPERTY_314": {
        "0": status
      },
      "PROPERTY_316": {
        "0": dayjs(new Date())
      },
      "PROPERTY_323": {
        "0": "В работе"
      }
    }

    if (numDoc) {
      fields["PROPERTY_292"] = {"0":numDoc};
    }
    if (amountDoc) {
      fields["PROPERTY_293"] = {"0":amountDoc};
    }
    if (crcValue) {
      fields["PROPERTY_294"] = {"0":crcValue};
    }

    if (!parentDocValue) {
      delete fields.PROPERTY_295;
    }
    if (!commentValue) {
      delete fields.DETAIL_TEXT;
    }

    console.log(fields);
    dispatch(setDeal(docId, fields, (id)=>{
      callback(id);
    }));
  }

  const handleToolBarRemove = ()=>{
    if (docId) {
      dispatch(removeDeal(docId, (res)=>{
        navigate('/', { state: {} });
      }))
    }
  }

  function validate() {
    let e = [];
    setErrMessage(e);
    if (!docType) {
      e.push("Выберите тип документа");
    }
    if (!whoLoad) {
      e.push("Кто отгружает?");
    }
    if (!partnerValue) {
      e.push("Введите имя контагента");
    }
    if (!signingTypeValue) {
      e.push("Выберите тип подписания");
    }
    if (!signerValue) {
      e.push("Выберите подписанта");
    }
    /*if (!numDoc) {
      e.push("Введите номер документа");
    }
    if (amountDoc === '0' || amountDoc === '' || amountDoc === 0) {
      e.push("Введите сумму");
    }
    if (!crcValue) {
      e.push("Выберите валюту");
    }*/
    if (!nameDocValue) {
      e.push("Введите название документа");
    }
    if (!initiatorValue) {
      e.push("Введите инициатора (по фамилии)");
    }
    if (!workerValue) {
      e.push("Введите исполнителя (по фамилии)");
    }
    if (e.length === 0) {
      return null;
    } else {
      return e;
    }
  }

  const handleToolBarSend = ()=>{
    /*let e = [];
    setErrMessage(e);
    if (!docId) {
      e.push("Сохраните документ");
    }
    if (!docType) {
      e.push("Выберите тип документа");
    }
    if (!whoLoad) {
      e.push("Кто отгружает?");
    }
    if (!partnerValue) {
      e.push("Введите имя контагента");
    }
    if (!signingTypeValue) {
      e.push("Выберите тип подписания");
    }
    if (!signerValue) {
      e.push("Выберите подписанта");
    }
    if (!numDoc) {
      e.push("Введите номер документа");
    }
    if (amountDoc === '0' || amountDoc === '' || amountDoc === 0) {
      e.push("Введите сумму");
    }
    if (!crcValue) {
      e.push("Выберите валюту");
    }
    if (!nameDocValue) {
      e.push("Введите название документа");
    }
    if (!initiatorValue) {
      e.push("Введите инициатора (по фамилии)");
    }
    if (!workerValue) {
      e.push("Введите исполнителя (по фамилии)");
    }
    if (e.length > 0) {
      setErrMessage(e);
      return;
    }*/

    let e = validate();
    if (e) {
      setErrMessage(e);
      return;
    }

    let status = "На проверке";

    let fields = {
      "PROPERTY_314": {
        "0": status
      },
    }
    console.log(fields);

    if (!docId) {
      saveDoc((id)=>{
        setDocId(id);
        dispatch(setDeal(id, fields, (id)=>{
          navigate('/', { state: {} });
        }));
      })
    } else {
      dispatch(setDeal(docId, fields, (id)=>{
        navigate('/', { state: {} });
      }));
    }
  }


  return (
    <ThemeProvider theme={stateObj.theme}>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <Column left={24} right={24} bottom={100}>
        <Row top={8}><ToolBar state="new" handleSave={handleSave} handleRemove={handleToolBarRemove} handleSend={handleToolBarSend} titleForSendDialog="Отправить документ на проверку?"></ToolBar ></Row>
        {errMessage.map((e)=>{
          return(<Row key={e}><Body1 color='red'>{e}</Body1></Row>)
        })}
        { loading &&
        <LinearProgress />
        } 
        <Row top={8} bottom={32}>
          <Header1>{docId ? "Согласование "+docId : "Новое согласование"}</Header1>
        </Row>
        <Row>
          <Row width={270}>
            <FormControl fullWidth>
              <InputLabel id="select-doc-type-label">Тип документа</InputLabel>
              <Select
                labelId="select-doc-type-label"
                id="select-doc-type"
                value={docType}
                label="Тип документа"
                onChange={handleChangeDocType}
              >
                {docTypesData && docTypesData.result.map((item)=>{
                  return(<MenuItem key={item.ID} value={item.ID}>{item.NAME}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
          <Row left={16} width={220}>
            <FormControl fullWidth>
              <InputLabel id="select-who-label">Кто отгружает</InputLabel>
              <Select
                labelId="select-who-label"
                id="select-who"
                value={whoLoad}
                label="Кто отгружает"
                onChange={handleChangeWhoLoad}
              >
                {who.map((item)=>{
                  return(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
          <Row left={16} width={290}>
            <Autocomplete
              value={partnerValue}
              onChange={handleChangePartner}
              inputValue={inputPartner}
              onInputChange={handleInputParnerChange}
              id="controllable-states-demo"
              options={partners ? partners : []}
              getOptionLabel={(option)=>(option.TITLE ? option.TITLE : '')}
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option.TITLE === value.TITLE
              }
              sx={{ width: 290 }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.ID}>
                    {option.TITLE}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Контрагент (min 3 симв.)" />}
            />
          </Row>
          <Row left={16} width={180}>
            <FormControl fullWidth>
              <InputLabel id="select-signing-type-label">Тип подписания</InputLabel>
              <Select
                labelId="select-signing-type-label"
                id="select-signing-type"
                value={signingTypeValue}
                label="Тип подписания"
                onChange={handleSigningType}
              >
                {signingType.map((item)=>{
                  return(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
          <Row left={16} width={220}>
            <FormControl fullWidth>
              <InputLabel id="select-signer-label">Подписант</InputLabel>
              <Select
                labelId="select-signer-label"
                id="select-signer"
                value={signerValue}
                label="Подписант"
                onChange={handleSigner}
              >
                {signers && signers.map((item)=>{
                  return(<MenuItem key={item.ID} value={item.ID}>{item.NAME}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
        </Row>
        <Row top={24}>
          <Column width={200}>
            <FormControl fullWidth>
              <DatePicker label="Дата документа" value={dateValue} onChange={handleChangeDate}></DatePicker>
            </FormControl>
          </Column>
          <Row left={16} width={180}>
            <TextField
              id="num-doc"
              label="Номер документа"
              value={numDoc}
              onChange={handleNumDoc}
            />
          </Row>
          <Row left={16} width={180}>
            <TextField
              id="amount-doc"
              label="Сумма документа"
              type='number'
              value={amountDoc}
              onChange={handleAmountDoc}
            />
          </Row>
          <Row left={16} width={120}>
            <FormControl fullWidth>
              <InputLabel id="select-crc-label">Валюта</InputLabel>
              <Select
                labelId="select-crc-label"
                id="select-crc"
                value={crcValue}
                label="Валюта"
                onChange={handleCrc}
              >
                {crc && crc.map((item)=>{
                  return(<MenuItem key={item.ID} value={item.ID}>{item.NAME}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
          <Row left={16} width={500}>
            <Autocomplete
              value={parentDocValue}
              onChange={handleParentDoc}
              inputValue={inputParent}
              onInputChange={handleInputParentDocChange}
              id="auto-parent"
              options={parents ? parents : []}
              getOptionLabel={(option)=>(option.NAME ? option.NAME : '')}
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option.NAME === value.NAME
              }
              sx={{ width: 500 }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.ID}>
                    {option.NAME}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Родительский документ, при наличии (min 3 симв.)" />}
            />
          </Row>
        </Row>
        <Row top={24}>
          <Column width={744}>
            <Row width="calc(100% - 16px)">
              <TextField
                fullWidth
                id="name-doc"
                label="Название документа"
                value={nameDocValue}
                onChange={handleNameDoc}
              />
            </Row>
            <Row top={24} width="calc(100% - 16px)">
              <Autocomplete
                value={initiatorValue}
                onChange={handleInitiator}
                inputValue={inputInitiator}
                onInputChange={handleInputInitiatorChange}
                id="auto-parent"
                options={initiators ? initiators : []}
                getOptionLabel={(option)=>(option.LAST_NAME ? option.NAME +" "+ option.LAST_NAME + " - " + option.WORK_POSITION : '')}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option.LAST_NAME === value.LAST_NAME
                }
                fullWidth
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.ID}>
                      {option.NAME} {option.LAST_NAME}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Инициатор (min 3 симв. фамилии)" />}
              />
            </Row>
            <Row top={24} width="calc(100% - 16px)">
              <Autocomplete
                value={workerValue}
                onChange={handleWorker}
                inputValue={inputWorker}
                onInputChange={handleInputWorkerChange}
                id="auto-parent"
                options={workers ? workers : []}
                getOptionLabel={(option)=>(option.LAST_NAME ? option.NAME +" "+ option.LAST_NAME + " - " + option.WORK_POSITION : '')}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option.LAST_NAME === value.LAST_NAME
                }
                fullWidth
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.ID}>
                      {option.NAME} {option.LAST_NAME}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Исполнитель (min 3 симв. фамилии)" />}
              />
            </Row>
            <Row top={24} width="calc(100% - 16px)">
              <TextField
                fullWidth
                id="comment-doc"
                label="Комментарий"
                multiline
                rows={5}
                value={commentValue}
                onChange={handleComment}
              />
            </Row>
          </Column>
          <Column width={500} >
            <FileList type="deal" height={fileListHeight} title="Файлы документа:" allowUploadFiles={true} allowDeleteFiles={true} docId={docId}></FileList>
          </Column>
        </Row>
      </Column>
    </LocalizationProvider>
    </ThemeProvider>
  );
}
  
export default NewDealPage;
  



