import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import FileList from './FlieList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { 
  getDeal,
  resetState,
  updateCalculatedLayout,
  setDeal,
  addReviewMessage
} from "./redux/actions";
import ToolBar from './ToolBar';
import DealInfoBlock from './DealInfoBlock';
import DealApprovalResultBlock from './DealApprovalResultBlock';
import ReviewTextBlock from './ReviewTextBlock';
import { Card, CardContent, IconButton } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { getPropValue } from './helpers';
import uuid from 'react-uuid';

function DealReadingPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const location = useLocation();
  const navigate = useNavigate();
  const docId = location.state.docId;
  const currentUser = stateObj.currentUser;
  const isUserInitiator = stateObj.isUserInitiator;
  const isUserWorker = stateObj.isUserWorker;
  const hideToolBar = location.state.hideToolBar;
  const onCloseNavigate = location.state.onCloseNavigate;
  const allowToSign = location.state.allowToSign;
  const lt = stateObj.lt;
  const $ = window.$;
  const [fileListHeight, setFileListHeight] = React.useState(400);
  const [checkData, setCheckData] = useState({checkId:0, checkLogs:[]});
  const [dealData, setDealData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    setLoading(true);
    dispatch(getDeal(docId, (deal)=>{
      setLoading(false);
      setDealData(deal);
      try {
        let n = getPropValue(deal, "PROPERTY_300");
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        setCheckData({checkId:n, checkLogs:logs});
      } catch(e) {}
    }))
  },[]);


  let dealInfoMinWidth = $(document).width()-600;

  const handleBack = () => {
    navigate(-1);
  }

  const handleForseToSign = () => {
    if (dealData && currentUser) {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = `0${dt.getMonth()+1}`.substr(-2);
      let d = `0${dt.getDate()}`.substr(-2);
      let currentDt = `${d}.${m}.${y}`;
      let fields = {
        "PROPERTY_314": {
          "0": "На подписании"
        },
        "PROPERTY_316": {
          "0": currentDt
        },
        "PROPERTY_323": {
          "0": "В работе"
        }
      }

      let newMsg = {id:uuid(), date:new Date(), text: "Документ направлен на подписание", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
        dispatch(addReviewMessage(dealData.ID, newMsg, (data)=>{
          console.log(data);
          dispatch(setDeal(dealData.ID, fields, (id)=>{
            navigate('/', { state: {} });
          }));
        }));
      
    }
  }

  const handleRevoke = ()=>{
    let deal = dealData;
    if (deal && currentUser) {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = `0${dt.getMonth()+1}`.substr(-2);
      let d = `0${dt.getDate()}`.substr(-2);
      let currentDt = `${d}.${m}.${y}`;
      let fields = {
        "PROPERTY_314": {
          "0": "Отозван"
        },
        "PROPERTY_316": {
          "0": currentDt
        }
      }

      let newMsg = {id:uuid(), date:new Date(), text: "Документ отозван с согласования", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
      dispatch(addReviewMessage(deal.ID, newMsg, (data)=>{
        console.log(data);
        dispatch(setDeal(deal.ID, fields, (id)=>{
          navigate('/', { state: {} });
        }));
      }));
    }
  }
  
  return (
    <ThemeProvider theme={stateObj.theme}>
      <Column left={24} right={24} bottom={80}>
        { !hideToolBar &&
          <Row top={8}><ToolBar state="read" onCloseNavigate={onCloseNavigate}></ToolBar ></Row>
        }
        { hideToolBar &&
          <Row top={24}><IconButton color='primary' onClick={handleBack}><ArrowBackIcon /></IconButton></Row>
        }
        { loading &&
        <LinearProgress />
        }
        <Row top={32} bottom={24}>
          <Header1>Просмотр документа №{docId}</Header1>
        </Row>
        <Row>
          <Column>
            <Card>
              <CardContent>
                <DealInfoBlock docId={docId} minWidth={dealInfoMinWidth} editable={false}></DealInfoBlock>
              </CardContent>
            </Card>
            <Column top={36}>
              <Card>
                <CardContent>
                  <Row top={16} bottom={24}><Header2>Результат согласования</Header2></Row>
                  <DealApprovalResultBlock checkData={checkData}></DealApprovalResultBlock>
                </CardContent>
              </Card>
            </Column>
            <Row top={36}><Header2>Статус документа</Header2></Row>
            <Row top={24}>
              <Subtitle1>{getPropValue(dealData, "PROPERTY_314")}</Subtitle1>
            </Row>
            <Row main={Axis.main.between} >
              {dealData && allowToSign && getPropValue(dealData, "PROPERTY_314") !== "Подписан" &&
              <Row top={24} sx={{width:'calc(100%)'}} ><Button fullWidth variant='contained' size='large' color='success' onClick={handleForseToSign}>Отправить на подписание</Button></Row>
              }
            </Row>
            <Row main={Axis.main.between} >
              { dealData && (isUserInitiator || isUserWorker) && getPropValue(dealData,"PROPERTY_314") !== "Отозван" &&
              <Row top={24} sx={{width:'calc(100%)'}} ><Button fullWidth variant='contained' size='large' color='error' onClick={handleRevoke}>Отозвать документ</Button></Row>
              }
            </Row>
          </Column>
          <Column left={16} width={500}>
            <FileList type="deal" height={fileListHeight} title="Файлы документа:" allowUploadFiles={false} allowDeleteFiles={false} docId={docId}></FileList>
            <Column top={16} bottom={16}>
              <Row top={0} bottom={12}><Subtitle1>Переписка по согласованию</Subtitle1></Row>
              <Card>
                <ReviewTextBlock docId={docId} allowSend={false}></ReviewTextBlock>
              </Card>
            </Column>
            { getPropValue(dealData, "PROPERTY_314") === "На подписании" || getPropValue(dealData, "PROPERTY_323") === "В архиве" && 
            <FileList type="deal-scan" height={fileListHeight} title="Сканы подписанного документа:" allowUploadFiles={false} allowDeleteFiles={false} docId={docId}></FileList>
            }
          </Column>
        </Row>
      </Column>
    </ThemeProvider>
  );
}
  
export default DealReadingPage;
  



