import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FileList from './FlieList';

import { 
    getDeal,
    resetState,
    updateCalculatedLayout,
    getDataForDeal,
    getCheck,
    getWorker,
    getLogs
} from "./redux/actions";
import { getPropValue } from './helpers';

function DealApprovalResultBlock(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;
  const [worker, setWorker] = useState(null);
  const [checkDate, setCheckDate] = useState(null);
  const [checkResult, setCheckResult] = useState(null);
  const [checkComments, setCheckComments] = useState(null);
  const checkId = props.checkData.checkId;
  const checkLogs = props.checkData.checkLogs;
  const [logs, setCheckLogs] = useState(null);

  useEffect(()=>{
    dispatch(getCheck(checkId, (checkDoc)=>{
      try {
        if (checkDoc) {
          //console.log(checkDoc);
          let workerId = getPropValue(checkDoc, "PROPERTY_284");
          setCheckDate(getPropValue(checkDoc, "PROPERTY_280"));
          setCheckComments(getPropValue(checkDoc, "PROPERTY_283"));
          setCheckResult(getPropValue(checkDoc, "PROPERTY_281"));
          dispatch(getWorker(workerId, (workerData)=>{
            setWorker(workerData);
          }));
          dispatch(getLogs(checkLogs, (data)=>{
            console.log(data);
            setCheckLogs(data);
          }));
        }
      } catch(e) {}
    }));

    
    
  },[checkId, checkLogs]);

  //console.log(checkLogs);

  let checkResultIndicatorSB = "gray";
  if (checkResult) {
    if (checkResult === "Без замечаний") {
      checkResultIndicatorSB = "green";
    } else {
      checkResultIndicatorSB = "red";
    }
  }

  let checkResultIndicatorUR = "gray";
  let checkResultIndicatorLOG = "gray";
  let checkResultIndicatorLAB = "gray";
  
  return (
    <Column>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="sb-header"
        >
          <Row main={Axis.main.between} width='100%'>
            <Row cross={Axis.cross.center}>
              <Indicator color={checkResultIndicatorSB}></Indicator>
              <Row left={8}><Subtitle2>СБ {checkResult ? `(${checkResult})` : ""}</Subtitle2></Row>
            </Row>
            <Row right={16}><p style={{fontSize:12, color:"gray"}}>{checkDate}</p></Row>
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
            <Row width={200}><Body2>Проверил:</Body2></Row>
            <Body1>{worker ? worker.NAME+" "+worker.LAST_NAME+" - "+worker.WORK_POSITION : ""}</Body1>
          </Row>
          <Row top={4}>
            <Row width={200}><Body2>Дата проверки:</Body2></Row>
            <Body1>{checkDate ? checkDate : ""}</Body1>
          </Row>
          <Row top={4}>
            <Row width={200}><Body2>Заключение:</Body2></Row>
            <Body1>{checkResult ? checkResult : ""}</Body1>
          </Row>
          <Row top={4}>
            <Row width={200}><Body2>Комментарий:</Body2></Row>
            <Body1>{checkComments ? checkComments.TEXT : ""}</Body1>
          </Row>
          <Column top={16} width={500} >
            <Row left={0}><FileList type="check" height={200} title="Файлы проверки:" allowUploadFiles={false} allowDeleteFiles={false} docId={checkId}></FileList></Row>
          </Column>
        </AccordionDetails>
      </Accordion>
      {logs && logs.length > 0 && logs.map((log)=>{
        let groupName = log.GROUP_INFO.NAME;
        let logApprovalDate = getPropValue(log,"PROPERTY_310");
        let logApprovalResult = getPropValue(log, "PROPERTY_307");
        let checkResultIndicator = "gray";
        if (logApprovalResult === "Согласовано") {
          checkResultIndicator = "green";
        } else if (logApprovalResult === "Отклонено") {
          checkResultIndicator = "red";
        }


        return(
          <Column key={log.ID}>
            <Row top={10}></Row>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={log.ID}
              >
                <Row main={Axis.main.between} width='100%'>
                  <Row cross={Axis.cross.center}>
                    <Indicator color={checkResultIndicator}></Indicator>
                    <Row left={8}><Subtitle2>{groupName}</Subtitle2></Row>
                  </Row>
                  <Row right={16}><p style={{fontSize:12, color:"gray"}}>{logApprovalDate}</p></Row>
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Row width={200}><Body2>Результат:</Body2></Row>
                  <Body1>{logApprovalResult ? logApprovalResult : ""}</Body1>
                </Row>
              </AccordionDetails>
            </Accordion>
          </Column>
        )
      })}
    </Column>
  );
}

function Indicator(props) {
  return(
    <Row sx={{width:8, height:8, borderRadius:15, backgroundColor:props.color}}></Row>
  )
}
  
export default DealApprovalResultBlock;
  



