import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { json, useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import uuid from 'react-uuid';
import styled from '@emotion/styled';

import { 
    getDeal,
    resetState,
    updateCalculatedLayout,
    sendReviewMessage,
    getReviewMessage,
    getCurrentUser,
    setDeal
} from "./redux/actions";
import { Button, IconButton, TextField } from '@mui/material';

function ReviewTextBlock(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const isUserSB = stateObj.isUserSB;
  const isUserInitiator = stateObj.isUserInitiator;
  const isUserWorker = stateObj.isUserWorker;
  const isUserSigner = stateObj.isUserSigner;
  const isUserInLogs = stateObj.isUserInLogs;
  const lt = stateObj.lt;
  const currentUser = stateObj.currentUser;
  const $ = window.$;
  const [messages, setMessages] = useState(null);
  //const [currentUser, setCurrentUser] = useState(null);

  //console.log(props.docId);

  useEffect(()=>{
    dispatch(getReviewMessage(props.docId, (data)=>{
      if (data) {
        try {
          setMessages(JSON.parse(data));
        } catch(e) {
          dispatch(sendReviewMessage(props.docId, "[]", (data)=>{}));
        }
      }
    }));
    /*if (!stateObj.currentUser) {
      dispatch(getCurrentUser(null, (data)=>{
        setCurrentUser(data);
      }))
    }*/
  },[]);

  const handleSendMessage = (e)=>{
    let value = $("#msg").closest("textarea").val();

    //clear
    /*dispatch(sendReviewMessage(props.docId, "[]", (data)=>{
      setMessages(null);
      $("#msg").closest("textarea").val('');
    }));
    return;*/

    if (!messages) {
      /*if (!currentUser) {
        dispatch(getCurrentUser((data)=>{
          setCurrentUser(data);
          let newMsg = [{id:uuid(), date:new Date(), text: value, user:data.NAME+" "+data.LAST_NAME, user_id:data.ID}]; 
          dispatch(sendReviewMessage(props.docId, JSON.stringify(newMsg), (data)=>{
            setMessages(JSON.parse(data));
            $("#msg").closest("textarea").val('');
          }));
        }))
      } else {
        
      }*/
      let need_comm;
      if (isUserInitiator || isUserWorker) {
        need_comm = "";
      } else {
        need_comm = "Да";
      }
      let fields = {
        "PROPERTY_315": {
          "0": need_comm
        },
      }    
      $("#msg").closest("textarea").val('');
      dispatch(setDeal(props.docId, fields, (id)=>{
        let newMsg = [{id:uuid(), date:new Date(), text: value, user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}]; 
        dispatch(sendReviewMessage(props.docId, JSON.stringify(newMsg), (data)=>{
          setMessages(JSON.parse(data));
        }));
      }));
    } else {
      let m = [...messages];
      let newMsg = {id:uuid(), date:new Date(), text: value, user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID};
      m.push(newMsg);
      setMessages(m);
      $("#msg").closest("textarea").val('');
      scrollSmoothlyToBottom("style-3");
      let need_comm;
      //console.log(isUserInitiator, isUserWorker, isUserInLogs, currentUser.ID);
      if (isUserInitiator || isUserWorker) {
        need_comm = "";
      } else {
        need_comm = "Да";
      }
      let fields = {
        "PROPERTY_315": {
          "0": need_comm
        },
      } 
      dispatch(setDeal(props.docId, fields, (id)=>{
        dispatch(sendReviewMessage(props.docId, JSON.stringify(m), (data)=>{
          
        }));
      }));
    }

    
  }

  const scrollSmoothlyToBottom = (id) => {
    const element = $(`#${id}`);
    element.animate({
        scrollTop: element.prop("scrollHeight")
    }, 500);
  }
  
  return (
    <Column>
      {/*<Row bottom={16}><Subtitle1>Переписка по согласованию:</Subtitle1></Row> */}
      <Column main={Axis.main.evenly} cross={Axis.cross.center}>
        <Column top={12} right={20} bottom={12} cross={Axis.cross.center} id="style-3" className="scrollbar" color="#146C94" width='calc(100% - 18px)' height={props.allowSend ? 380 : 490} sx={{ borderRadius:5, overflowY: 'auto', overflowX:'hidden'}}>
          {messages && messages.map((message, i)=>{
            let me = 1;
            if (i % 2 === 0) {
              me = 0;
            }
            return(
              <Message key={message.id} message={message} me={me}></Message>
            )
          })}
        </Column>
        {props.allowSend &&
          <Row height={110} width='100%' main={Axis.main.between}>
            <Column left={12} width='calc(100% - 60px - 8px)'><TextField fullWidth multiline rows={3} id='msg'></TextField></Column>
            <Row width={50} height={50} main={Axis.main.center}><Button color='primary' onClick={handleSendMessage}><SendOutlinedIcon /></Button></Row>
          </Row>
        }
      </Column>
    </Column>
  );
} 

function Message(props) {
  let dt = new Date(props.message.date);
  let y = dt.getFullYear();
  let m = `0${dt.getMonth()+1}`.substr(-2);
  let d = `0${dt.getDay()}`.substr(-2);
  let h = dt.getHours();
  let mn = dt.getMinutes();

  let date = `${d}.${m}.${y} ${h}:${mn}`;

  let contColor = "#F6F1F1";
  if (props.me) {
    contColor = "#DDF2FD";
  }

  let offsetLeft = 6;
  let offsetRight = 6;

  return(
    <MsgContainer left={offsetLeft} right={offsetRight} top={6} bottom={6} sx={{paddingTop:8, paddingBottom:8, paddingLeft:8, paddingRight:8}} me={props.me} color={contColor}>
      <Row main={Axis.main.between}><MsgUser color="#4F6F52">{props.message.user}</MsgUser><MsgDate color="#4F6F52">{date}</MsgDate></Row>
      <Row><MsgText color="#001524" top={5}>{props.message.text}</MsgText></Row>
    </MsgContainer>
  )
}

const MsgContainer = styled(Column)(props => ({
  backgroundColor: props.color ? props.color : 'inherit',
  width:"calc(100% - 16px - 12px)",
  borderRadius:"5px",
  marginTop:'6px',
  marginBottom:'6px',
  marginLeft:'6px',
  marginRight:'6px',
  paddingTop:'8px',
  paddingBottom:'8px',
  paddingLeft:'8px',
  paddingRight:'8px',
}));

const MsgDate = styled.p(props => ({
  fontWeight:400,
  fontSize:'10px',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

const MsgUser = styled.p(props => ({
  fontWeight:400,
  fontSize:'10px',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

const MsgText = styled.p(props => ({
  fontWeight:400,
  fontSize:'12px',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));
  
export default ReviewTextBlock;
  



