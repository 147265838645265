import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import {Link, useNavigate} from 'react-router-dom';
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug, getMatches } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _, { ceil } from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AdjustIcon from '@mui/icons-material/Adjust';
import DeblurIcon from '@mui/icons-material/Deblur';
import DrawIcon from '@mui/icons-material/Draw';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import PreviewIcon from '@mui/icons-material/Preview';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';

import { 
    resetState,
    updateCalculatedLayout,
    getDeals,
    getCurrentUser,
    getSigners,
    getLogs
} from "./redux/actions";
import ToolBar from './ToolBar';
import { IconButton } from '@mui/material';
import { getPropValue } from './helpers';
import { parallel } from 'async';

function ArchivePage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const archivedDeals = stateObj.archivedDeals;
    const currentUser = stateObj.currentUser;
    const signers = stateObj.signers;
    const $ = window.$;
    const navigate = useNavigate();
    const [sb, setSb] = useState(false);
    const [isUserInitiator, setIsUserInitiator] = useState(false);
    const [isUserWorker, setIsUserWorker] = useState(false);
    const [isUserSigner, setIsUserSigner] = useState(false);
    const [isUserInLogs, setIsUserInLogs] = useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [loading, setLoading] = useState(false);
      
    const records = [
      {id:1, type:"Договор поставки", partner:"Ромашка", name:"О поставке товара", date:"21.12.2023", num:"123", status:"Черновик", task:"Заполнить"},
      {id:2, type:"Договор поставки", partner:"Ромашка", name:"О поставке товара", date:"21.12.2023", num:"123", status:"Черновик", task:"Заполнить"},
      {id:3, type:"Договор поставки", partner:"Ромашка", name:"О поставке товара", date:"21.12.2023", num:"123", status:"Черновик", task:"Заполнить"},
      {id:4, type:"Договор поставки", partner:"Ромашка", name:"О поставке товара", date:"21.12.2023", num:"123", status:"Черновик", task:"Заполнить"},
      {id:5, type:"Договор поставки", partner:"Ромашка", name:"О поставке товара", date:"21.12.2023", num:"123", status:"Черновик", task:"Заполнить"},
    ]
  
    useEffect(()=>{
      setLoading(true);
      dispatch(getDeals(false, (deals)=>{
        //console.log(currentUser);
        generateDealList(deals, currentUser);
      }));
    },[searchText]);

    function generateDealList(deals, user) {
      let isSB = false;
      _.forEach(user.GROUPS, (g)=>{
        if (g.NAME === "Служба безопасности") {
          setSb(true);
          isSB = true;
          return false;
        }
      });
      let allDeals = [];
      if (isSB) {
        allDeals = deals;
      }
      let initiatorDeals = getDealsForInititor(deals, user);
      if (initiatorDeals.length > 0) {
        setIsUserInitiator(true);
      }
      let workerDeals = getDealsForWorker(deals, user);
      if (workerDeals.length > 0) {
        setIsUserWorker(true);
      }
      allDeals = allDeals.concat(initiatorDeals);
      allDeals = allDeals.concat(workerDeals);
      getDealsForSigner(deals, user, (signerDeals)=>{
        if (signerDeals.length > 0) {
          allDeals = allDeals.concat(signerDeals);
          setIsUserSigner(true);
        }
        getDealsForUsersFromLosgs(deals, user, (logDeals)=>{
          console.log("log deals", logDeals);
          if (logDeals.length > 0) {
            allDeals = allDeals.concat(logDeals);
            setIsUserInLogs(true);
          }
          allDeals = _.uniqBy(allDeals, x => x.ID);
          allDeals = _.orderBy(allDeals, (x)=>{ return x.ID}, ["desc"]);
          if (searchText) {
            let temp = [];
            _.forEach(allDeals, (x)=>{
              let v = getPropValue(x, "NAME", -99);
              console.log(v);
              if(v && v.toLowerCase().includes(searchText.toLowerCase())) {
                temp.push(x);
              }
            })
            if (temp.length > 0) {
              allDeals = temp;
            }
          }
          console.log(allDeals);
          dispatch(resetState({archivedDeals:allDeals}));
          setLoading(false);
        });
        
      });
    }

    function getDealsForUsersFromLosgs(deals, user, callback) {
      let funcs = [];
      _.forEach(deals, (deal)=>{
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        funcs.push((callback)=>{
          dispatch(getLogs(logs, (logsData)=>{
            console.log(logsData);
            let d = [];
            _.forEach(logsData, (log)=>{
              let usersInGroup = getPropValue(log.GROUP_INFO, "PROPERTY_276", -1);
              let userIdInGroup = _.find(usersInGroup, x => x === user.ID);
              if (userIdInGroup) {
                if (!deal.hasOwnProperty("LOGS_DATA")) {
                  deal.LOGS_DATA = [];
                }
                deal.LOGS_DATA.push(log);
                d.push(deal);
              }
            })
            callback(null, d);
          }))
        });
      })
      parallel(funcs, (err, results)=>{
        let flat = _.flatten(results);
        callback(flat);
      })
    }

    function getDealsForInititor(deals, user) {
      let newDeals = [];
      _.forEach(deals, (deal)=>{
        let p = getPropValue(deal, "PROPERTY_297");
        if (p && p === user.ID) {
          newDeals.push(deal);
        }
      })
      return newDeals;
    }

    function getDealsForWorker(deals, user) {
      let newDeals = [];
      _.forEach(deals, (deal)=>{
        let p = getPropValue(deal, "PROPERTY_312");
        if (p && p === user.ID) {
          newDeals.push(deal);
        }
      })
      return newDeals;
    }

    function getDealsForSigner(deals, user, callback) {
      let newDeals = [];
      if (!signers) {
        dispatch(getSigners((res)=>{
          console.log(res);
          let signer = _.find(res, x => getPropValue(x, "PROPERTY_277") === user.ID);
          if (signer) {
            _.forEach(deals, (deal)=>{
              let p = getPropValue(deal, "PROPERTY_290");
              //console.log(p);
              if (p === signer.ID) {
                newDeals.push(deal);
              }
            })
          }
          callback(newDeals);
        }))
      } else {
        //console.log(signers);
        let signer = _.find(signers, x => getPropValue(x, "PROPERTY_277") === user.ID);
        if (signer) {
          _.forEach(deals, (deal)=>{
            let p = getPropValue(deal, "PROPERTY_290");
            //console.log(p, signer.ID);
            if (p === signer.ID) {
              newDeals.push(deal);
            }
          })
        }
        callback(newDeals);
      }
    }

    let toolbarButtons;
    if (sb) {
      toolbarButtons = {new:true, process:false, archive:true, save:true, remove:true,close:true,send:true,settings:true};
    }
  
    console.log(currentUser);

    const handlePageChange = (e, value)=>{
  
    }

    /*const handleToolBarClose = () => {
      navigate("/archive");
    }*/

    const handleExamine = (e)=>{
      let id = $(e.target).closest("tr").attr("data-rec");
      if (!isUserSigner) {
        navigate('/read',  {state:{docId: id, onCloseNavigate: "/archive"}});
      } else {
        navigate('/read',  {state:{docId: id, onCloseNavigate: "/archive", allowToSign:true}});
      }
    }
    
    const handleSearchText = (e) => {
      setSearchText(e.target.value);
    }
    
    return (
      <ThemeProvider theme={stateObj.theme}>
        <Column left={24} right={24}>
          <Row top={8}><ToolBar state="archive" buttons={toolbarButtons}></ToolBar ></Row>
          { loading &&
          <LinearProgress />
          }
          <Row top={32} bottom={16} cross={Axis.cross.center} main={Axis.main.between}>
            <Header1>Архив согласований</Header1>
            <Row width={300}>
              <TextField
                fullWidth
                id="search-text"
                label="Поиск"
                value={searchText}
                onChange={handleSearchText}
              />
            </Row>
          </Row>
          <Column>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{backgroundColor:"#d8e2ff"}}>
                    <TableCell align="left"><Header4>ID</Header4></TableCell>
                    <TableCell align="left"><Header4>Тип</Header4></TableCell>
                    <TableCell align="left"><Header4>Контрагент</Header4></TableCell>
                    <TableCell align="left"><Header4>Название</Header4></TableCell>
                    <TableCell align="left"><Header4>Дата</Header4></TableCell>
                    <TableCell align="left"><Header4>Номер</Header4></TableCell>
                    <TableCell align="left"><Header4>Статус</Header4></TableCell>
                    {/*<TableCell align="center"><Header4>Ваши задания</Header4></TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { archivedDeals && archivedDeals.map((rec, i)=>{
                    let docType = getMatches(rec.NAME, /[–-]\s*(.+)?[№#]/g, 1);
                    let name = getPropValue(rec, "PROPERTY_296");
                    let date = getPropValue(rec, "PROPERTY_291");
                    let num = getPropValue(rec, "PROPERTY_292");
                    let status = getPropValue(rec, "PROPERTY_314");
                    let partner = getMatches(rec.NAME, /^(.+)?\s[–-]/g, 1);
                    let check;
                    if (rec.PROPERTY_300) {
                      check = getPropValue(rec, "PROPERTY_300");
                    }
                    return(
                      <TableRow key={rec.ID} data-rec={rec.ID}>
                        <TableCell><Button variant='outlined' onClick={handleExamine}>{rec.ID}</Button></TableCell >
                        <TableCell>{docType}</TableCell>  
                        <TableCell>{partner}</TableCell>  
                        <TableCell>{name}</TableCell>  
                        <TableCell>{date}</TableCell>  
                        <TableCell>{num}</TableCell>  
                        <TableCell>{status}</TableCell>  
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<Row top={24} bottom={0} center>
              <Pagination count={10} page={1} onChange={handlePageChange} />
                </Row>*/}
          </Column>
        </Column>
      </ThemeProvider>
    );
  }
  
export default ArchivePage;
  



