import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Card, CircularProgress, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "@emotion/styled/macro";
import axios from 'axios';
import _ from 'lodash';
import { series } from 'async';
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { 
  prepUrl
} from "./redux/actions";

function FileList(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;
  const [errMessage, setErrMessage] = useState('');
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    listFiles();
  },[props.docId]);

  function listFiles() {
    let blockId;
    let fileProperty;
    if (props.docId) {
      if (props.type === "deal") {
        blockId = 60;
        fileProperty = "PROPERTY_319";
      } else if (props.type === "check") {
        blockId = 61;
        fileProperty = "PROPERTY_320";
      } else if (props.type === "deal-scan") {
        blockId = 60;
        fileProperty = "PROPERTY_321";
      }
      let p = prepUrl(stateObj.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "FILTER": {"=ID":props.docId} });
      axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
        let data = response.data.result[0];
        console.log(data, fileProperty);
        if (data.hasOwnProperty(fileProperty)) {
          let keys = Object.keys(data[fileProperty]);
          let fs = [];
          let funcs = [];
          _.forEach(keys, (key)=>{
            _.forEach(data[fileProperty][key], (f)=>{
              funcs.push(function(callback) {
                let p = prepUrl(stateObj.rest, "disk.attachedObject.get", { "id":f });
                axios.post({url:p.url, method:"post", data: p.params}).then((response)=>{
                  let fileMeta = response.data.result;
                  console.log(fileMeta);
                  let d = new Date(fileMeta.CREATE_TIME);
                  let yyyy = d.getFullYear();
                  let m = `0${d.getMonth()+1}`.substr(-2);
                  let dd = `0${d.getDate()}`.substr(-2);
                  callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                }).catch((response)=>{
                  //console.log(response);
                  let p = prepUrl(stateObj.rest, "disk.file.get", { "id":f });
                  axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
                    let fileMeta = response.data.result;
                    console.log(fileMeta);
                    let d = new Date(fileMeta.CREATE_TIME);
                    let yyyy = d.getFullYear();
                    let m = `0${d.getMonth()+1}`.substr(-2);
                    let dd = `0${d.getDate()}`.substr(-2);
                    callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                  }).catch((response)=>{
                    setLoading(false);
                    console.log(response);
                  })
                })
              });              
            })
          });
          series(funcs, function(err, results) {
            setLoading(false);
            setFiles(results);
          });
        } else {
          setLoading(false);
          setFiles(null);
        }
      })
    }
  }

  const onFileChange = (e)=>{
    //setErrMessage('');
    console.log(props.docId);
    if (!props.docId) {
      setErrMessage("Чтобы добавить файлы, сохраните документ");
      $("#file-upload")[0].value = '';
      setTimeout(()=>{
        setErrMessage('');
      },3000)
      return;
    }
    let selectedFile = e.target.files[0];
    //setSelectedFile(e.target.files[0]);
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "myFile",
      selectedFile,
      selectedFile.name
    );
    formData.append("docId", props.docId);
    let url = "https://miradeals.innadepot.com/upload.php";
    if (props.type === "deal") {
      formData.append("blockId", 60);
      formData.append("fileProperty", "PROPERTY_319");
    } else if (props.type === "check") {
      formData.append("blockId", 61);
      formData.append("fileProperty", "PROPERTY_320");
    } else if (props.type === "deal-scan") {
      formData.append("blockId", 60);
      formData.append("fileProperty", "PROPERTY_321");
    }
    setLoading(true);
    axios.post(url, formData, {maxBodyLength: Infinity, maxContentLength: Infinity, onUploadProgress: (e)=>{     
      let percent = Math.floor((100 * e.loaded) /e.total)
      console.log('Percent', percent)
    }}).then((result)=>{
      if (result.status === 200) {
        console.log(result.data);
        listFiles();
        $("#file-upload")[0].value = '';
      }
    });
  }

  let footerHeight = 0;
  if (props.allowUploadFiles) {
    footerHeight = 60;
  }

  const handleDeleteFile = (e)=>{
    try {
      let fileId = $(e.target).closest("td").attr("data-rec");
      if (props.docId) {
        let blockId;
        let fileProperty; 
        if (props.type === "deal") {
          blockId = 60;
          fileProperty = "PROPERTY_319"; 
        } else if (props.type === "check") {
          blockId = 61;
          fileProperty = "PROPERTY_320"; 
        } else if (props.type === "deal-scan") {
          blockId = 60;
          fileProperty = "PROPERTY_321"; 
        }
        let p = prepUrl(stateObj.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "FILTER": {"=ID":props.docId} });
        setLoading(true);
        axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
          let data = response.data.result[0];
          if (data.hasOwnProperty(fileProperty)) {
            let keys = Object.keys(data[fileProperty]);
            _.forEach(keys, (key)=>{
              let cp = [];
              _.forEach(data[fileProperty][key], (f, i)=>{
                if (fileId !== f) {
                  cp.push(f);
                }            
              });
              data[fileProperty][key] = [...cp];
            });
            if (data[fileProperty].length === 0) {
              delete data[fileProperty];
            }
            let p = prepUrl(stateObj.rest, "lists.element.update", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "ELEMENT_ID": props.docId, "FIELDS":data });
            axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
              listFiles();
            }).catch((error)=>{
              setLoading(false);
              console.log(error);
            })
          }
        }).catch((error)=>{
          console.log(error);
        })
      }
    } catch(e) {

    }
  }

  return(
  <ThemeProvider theme={stateObj.theme}>
    <Column width='100%'>
      <Row height={35}><Subtitle1>{props.title}</Subtitle1></Row>
      <Card>
        <TableContainer sx={{ maxHeight: props.height-35-footerHeight }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TD align="left" sx={{backgroundColor:"#eaeefa"}}><Header4>Наименование</Header4></TD>
                <TD align="left" sx={{backgroundColor:"#eaeefa"}}><Header4>Дата</Header4></TD>
                {props.allowDeleteFiles && 
                  <TD align="center" sx={{backgroundColor:"#eaeefa"}}><Header4>Удалить</Header4></TD>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              { files && files.map((file, i)=>{
                return(
                  <TableRow key={file.id}>
                    <TD><Tooltip title={file.name}><a href={file.url}><DocName>{file.name}</DocName></a></Tooltip></TD>  
                    <TD>{file.date}</TD>  
                    {props.allowDeleteFiles && 
                      <TD data-rec={file.id}>
                        <Row main={Axis.main.center}>
                          <IconButton data-rec={file.id} size="large" color='primary' onClick={handleDeleteFile}><DeleteIcon /></IconButton>
                        </Row>
                      </TD>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        { props.allowUploadFiles &&
          <Row height={footerHeight} main={Axis.main.start} cross={Axis.cross.center} color="#f1f3fc">
            <Column width='100%'>
              <Row left={8}>
                <LabelFileUpload htmlFor="file-upload"><Row height={44} top={11} left={12} right={12}><Header5 color="white">{files && files.length > 0 ? "Загрузить еще" : "Загрузить файл"}</Header5></Row></LabelFileUpload>
                <input id="file-upload" type="file" style={{display:"none"}} onChange={onFileChange} />
                <Row left={16}>
                { loading &&
                  <CircularProgress />
                }
                </Row>
              </Row>
            </Column>
            <Column>
              <Row left={16}><Body1 color='red'>{errMessage}</Body1></Row>
            </Column>
          </Row>
        }
      </Card>
      
    </Column>
  </ThemeProvider>
  )
}

const LabelFileUpload = styled('label')`
  border: 0px solid #ccc;
  background-color:#1976d2;
  display: block;
  cursor: pointer;
  height:42px;
`;

const DocName = styled('p')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;

const TD = styled(TableCell)`
  padding:0px;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:16px;
  padding-right:8px;
`;


export default FileList;